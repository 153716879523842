import * as MUI from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

interface Props {
    title: ReactNode | string;
    children: ReactElement<any, any>;
}

class Tooltip extends React.Component<Props, any> {
    state: Readonly<any> = {};

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <MUI.Tooltip title={this.props.title} disableInteractive sx={{ maxWidth: 100 }} enterDelay={500}>
                    {this.props.children}
                </MUI.Tooltip>
            </>
        );
    }
}

export default Tooltip;
